export const AUTH_ACCESS_TOKEN = 'auth_access_token';
export const ACTOR_PROFILE = 'actor_profile';
export const OPS_SPACE = 'operations';
export const DEVICE_TOKEN = 'device_token';
export const ACCESS_TOKEN = 'access_token';
export const REFRESH_TOKEN = 'refresh_token';
export const CAPABILITIES = 'capabilities';
export const USER = 'user';
export const DASH = '—';
export const PLUS_SIGN = '+';
export const PLUS_ENCODED = '%2B';
export const MAX_ENTITIES_IN_RESPONSE = 100;
// minutes to milliseconds
export const FIFTEEN_MINUTES = 900 * 1000;
export const SIX_MINUTES = 360 * 1000;
export const FIVE_MINUTES = 300 * 1000;
export const THIRTY_SECONDS = 30 * 1000;
export const TEN_SECONDS = 10 * 1000;
export const OTP_CODE_LENGTH_6 = 6;
export const OTP_CODE_LENGTH_5 = 5;
export const minimumDate = new Date(1987, 5, 3);
export const UUID_LENGTH = 36;
export const SUPPORT_EMAIL = 'support@dock.financial';
export const DEFAULT_SORT_BY = 'createdTime';
export const DEFAULT_RECURSIVE = false;
export const CONTINUATION_TOKEN_RESPONSE_HEADER = 'x-continuation-token';
export const IDEMPOTENCY_KEY_REQUEST_HEADER = 'idempotency-key';
export const IF_MATCH_REQUEST_HEADER = 'if-match';
export const AUTHORIZATION_KEY_REQUEST_HEADER = 'Authorization';
export const TIMEOUT = 25000;
